.company-history {
  padding: 2rem;
  text-align: center;
  position: relative;
}

.history-buttons {
  margin-bottom: 0.2rem;
}

.history-buttons button {
  margin:  0.5rem;
  padding: 0.5rem 1rem;
  background-color: #009688;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 20px;
}

.timeline {
  position: relative;
  width: 100%;
}

.timeline-event {
  position: relative;
  width: 35%;
  padding: 2rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 3rem;
}

.timeline-event.left {
  left: 50%;
  transform: translateX(-110%); /* 중앙 선에서 10px 떨어지게 조정 */
  text-align: left;
}

.timeline-event.right {
  left: 50%;
  transform: translateX(10%); /* 중앙 선에서 10px 떨어지게 조정 */
  text-align: left;
}

.timeline-event .timeline-content {
  background: #e0f7fa;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  position: relative;
  text-align: left;
  width: calc(100% - 30px);
  border: 2px solid #009688;
}

.timeline-event h3 {
  margin: 0 0 1rem 0;
  color: #00796b;
}

.timeline-event ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.timeline-event ul li {
  margin-bottom: 0.75rem;
  position: relative;
  padding-left: 25px;
  color: #004d40;
}

.timeline-event ul li:before {
  content: '•';
  position: absolute;
  left: 0;
  color: #009688;
}

/* Central stem line */
.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 100%;
  background: #009688;
}

/* Branch lines */
.timeline-event.left:before{
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100px; /* 중앙 선에서 박스까지의 거리 조정 */
  height: 4px;
  background: #009688;
}

.timeline-event.right:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100px; /* 중앙 선에서 박스까지의 거리 조정 */
  height: 4px;
  background: #009688;
}

.timeline-event.left:before {
  right: -65px; /* 선이 중앙 줄기와 이어지도록 위치 수정 */
}

.timeline-event.right:before {
  left: -65px; /* 선이 중앙 줄기와 이어지도록 위치 수정 */
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .timeline-event {
    width: 80%;
    left: 10% !important;
    text-align: center;
  }

  .timeline:before {
    left: 10%;
  }

  .timeline-event.left:before,
  .timeline-event.right:before {
    width: 10%;
    left: -10%;
  }
}

/* 반응형 디자인 */
@media screen and (max-width: 768px) {
  .timeline-event {
    width: 80%;
    left: 50% !important;
    transform: translateX(-50%) !important;
    text-align: center;
    margin-bottom: 1rem;
  }

  .timeline:before {
    left: 50%;
    width: 2px;
  }

  .timeline-event.left:before,
  .timeline-event.right:before {
    width: 0;
    background: none;
  }
}