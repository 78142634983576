.footer {
  background-color: #34d1c9;
  color: white;
  padding: 1rem;
  position: relative;
}

.footer-text {
  text-align: center;
  position: relative;
}

.admin-link {
  position: absolute;
  right: 0;
}

.admin-link a {
  color: white;
  text-decoration: none;
}

.admin-link a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .hero-section {
    height: 300px;
  }

  h2 {
    font-size: 2rem;
  }

  .service-button {
    width: 100%;
    font-size: 1rem;
  }

  .services-grid {
    gap: 1rem;
  }

  .vision-video {
    width: 100%;
  }

  .vision-video > div {
    padding-top: 56.25%;
  }
}