.main-content {
    background: #f3f3f7 !important; /* 배경 색상 설정 */
    padding: 2rem; /* 내부 여백 설정 */
    box-shadow: 0 2px 4px rgba(19, 16, 16, 0.1); /* 그림자 설정 */
    position: relative; /* 상대 위치 설정 */
    text-align: center; /* 텍스트 가운데 정렬 */
    color: #000000 !important; /* 텍스트 색상 설정 */
    margin: 0; /* 여백 제거 */
    width: 100vw; /* 너비를 화면 전체로 설정 */
    box-sizing: border-box; /* 패딩과 테두리를 포함하여 너비 계산 */
}

/* 전체 페이지 스타일 설정 */
.intellectual-property {
    padding: 0; /* 내부 여백 설정 */
    text-align: center; /* 텍스트를 가운데 정렬 */
}
  
/* 헤더 부분 스타일 설정 */
.intellectual-property-header {
    margin: 0; /* 하단 여백 설정 */
    padding: 0; /* 상단 여백 설정 */
}
  
.intellectual-property-header h1,
.intellectual-property-header h2,
.intellectual-property-header p {
    font-size: 2.5rem; /* 헤더 폰트 크기 설정 */
    margin-bottom: 1rem; /* 하단 여백 설정 */
    color: #000000 !important; /* 텍스트 색상 설정 */
}
  
.intellectual-property-header p {
    font-size: 1.25rem; /* 본문 폰트 크기 설정 */
    margin: 0.5rem 0; /* 위, 아래 여백 설정 */
}
  
/* 통계 부분 스타일 설정 */
.intellectual-property-stats {
    display: flex; /* 플렉스 박스 사용하여 수평 배치 */
    justify-content: center; /* 자식 요소들을 가운데 정렬 */
    align-items: center; /* 자식 요소들을 세로로 가운데 정렬 */
    margin-bottom: 2rem; /* 하단 여백 설정 */
}
  
.stat {
    margin: 0 2rem; /* 좌우 여백 설정 */
    text-align: center; /* 텍스트 가운데 정렬 */
}
  
.stat h2 {
    font-size: 2rem; /* 폰트 크기 설정 */
    margin-bottom: 0.5rem; /* 하단 여백 설정 */
}
  
.stat p {
    font-size: 2rem; /* 폰트 크기 설정 */
    font-weight: bold; /* 폰트 두께 설정 */
}
  
/* 인증서 섹션 스타일 설정 */
.certificates {
    margin-top: 2rem; /* 상단 여백 설정 */
}
  
.certificates h2 {
    font-size: 2rem; /* 폰트 크기 설정 */
    margin-bottom: 1rem; /* 하단 여백 설정 */
}
  
/* 인증서 그리드 스타일 설정 */
.certificates-grid {
    display: grid; /* 그리드 레이아웃 사용 */
    grid-template-columns: repeat(4, 1fr); /* 한 줄에 4개의 칸 배치 */
    gap: 1rem; /* 칸 사이의 간격 설정 */
    max-width: 1000px; /* 최대 너비 설정 */
    margin: 0 auto; /* 가운데 정렬 */
}
  
/* 개별 인증서 스타일 설정 */    
.certificate {
    background-color: #ededf8 ; /* 배경 색상 설정 */
    border: 1px solid #ddd; /* 테두리 색상 설정 */
    border-radius: 20px; /* 모서리를 둥글게 설정 */
    padding: 1rem; /* 내부 여백 설정 */
    text-align: center; /* 텍스트 가운데 정렬 */
    width: 100%; /* 너비 설정 */
    height: 130px; /* 높이 설정 */
    box-sizing: border-box; /* 패딩과 테두리를 포함하여 너비와 높이 계산 */
    display: flex; /* Flexbox 사용 */
    flex-direction: column; /* 세로 방향으로 정렬 */
    justify-content: center; /* 공간을 균등하게 분배하여 가운데로 정렬 */
    align-items: center; /* 수평 중앙 정렬 */
    cursor: pointer; /* 커서 모양 변경 */
}
  
.certificate p {
    font-size: 1.25rem; /* 폰트 크기 설정 */
    margin-bottom: 0.5rem; /* 하단 여백 설정 */
    line-height: 1.5; /* 줄 간격 설정 */
}
  
/* 모달 창 스타일 설정 */
.modal {
    position: fixed; /* 고정 위치 */
    top: 50%; /* 상단에서 50% 위치 */
    left: 50%; /* 좌측에서 50% 위치 */
    transform: translate(-50%, -50%); /* 중앙 정렬 */
    width: 80%; /* 너비 설정 */
    max-width: 800px; /* 최대 너비 설정 */
    background: #fff; /* 배경 색상 설정 */
    padding: 1rem; /* 내부 여백 설정 */
    border-radius: 8px; /* 모서리를 둥글게 설정 */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* 그림자 설정 */
}
  
/* 오버레이 스타일 설정 */
.overlay {
    position: fixed; /* 고정 위치 */
    top: 0; /* 상단에서 0 위치 */
    left: 0; /* 좌측에서 0 위치 */
    width: 100%; /* 너비 100% 설정 */
    height: 100%; /* 높이 100% 설정 */
    background: rgba(0, 0, 0, 0.5); /* 반투명 검정 배경 */
}
  
/* 모달 창 닫기 버튼 스타일 설정 */
.close-button {
    position: absolute; /* 절대 위치 */
    top: 10px; /* 상단에서 10px 위치 */
    right: 10px; /* 우측에서 10px 위치 */
    background: none; /* 배경 제거 */
    border: none; /* 테두리 제거 */
    font-size: 1.5rem; /* 폰트 크기 설정 */
    cursor: pointer; /* 커서 모양 변경 */
}
  
/* 모달 창 이미지 스타일 설정 */
.modal-image {
    width: 100%; /* 너비 100% 설정 */
    height: auto; /* 높이를 자동으로 조절 */
}
  
/* content 박스 스타일 설정 */
.content {
    background: #cfcfdd ; /* 배경 색상 설정 */
    padding: 1rem; /* 내부 여백 설정 */
    border-radius: 20px; /* 모서리를 둥글게 설정 */
    box-shadow: 0 2px 4px rgba(19, 16, 16, 0.1); /* 그림자 설정 */
    position: relative; /* 상대 위치 설정 */
    text-align: center; /* 텍스트 가운데 정렬 */
    width: 20%; /* 적절한 너비 설정 (좌우 여백을 고려하여 조정) */
    margin: 0 auto; /* 가운데 정렬 */
    padding: 0.5rem 1rem; /* 내부 여백 설정 */
    color: #0a0707; /* 텍스트 색상 설정 */
}

.patents-buttons button {
    margin: 0 1rem;
    padding: 0.7rem;
    background-color: #b4b8b7;
    color: #000000;
    border: none;
    cursor: pointer;
    border-radius: 10px; /* 둥근 버튼으로 변경 */
  }

  @media (max-width: 768px) {
    .hero-section {
      height: 300px;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    .service-button {
      width: 100%;
      font-size: 1rem;
    }
  
    .services-grid {
      gap: 1rem;
    }
  
    .vision-video {
      width: 100%;
    }
  
    .vision-video > div {
      padding-top: 56.25%;
    }
  
    .certificates-grid {
      grid-template-columns: repeat(2, 1fr); /* 한 줄에 2개의 칸 배치 */
    }
  
    .certificate {
      height: auto; /* 높이를 자동으로 설정 */
      padding: 1rem; /* 내부 여백 설정 */
    }
  
    .certificate p {
      font-size: 1rem; /* 폰트 크기 줄이기 */
    }
  
    .modal {
      width: 90%; /* 너비 설정 */
      padding: 1rem; /* 내부 여백 설정 */
    }
  }