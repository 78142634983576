.request-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.request-form h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
}

.request-form input, .request-form textarea {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 20px; /* 모서리를 둥글게 설정 */
}

.request-form textarea {
    height: 300px;
}

.request-form button {
    padding: 1rem 2rem;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.request-form button:hover {
    background-color: #333;
}

@media (max-width: 768px) {
    .hero-section {
      height: 300px;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    .service-button {
      width: 100%;
      font-size: 1rem;
    }
  
    .services-grid {
      gap: 1rem;
    }
  
    .vision-video {
      width: 100%;
    }
  
    .vision-video > div {
      padding-top: 56.25%;
    }
  }