.main-page {
  text-align: center;
}

.hero-section {
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center;
}

.locations-section {
  padding: 0rem;
  background-size: cover;
  background-position: center;
}

.vision-section {
  width: 100%;
  padding: 0; /* 여백 제거 */
  background-size: cover;
  background-position: center;
}

.services-section {
  padding: 10rem 1rem;  /* 패딩을 크게 설정하여 여유 공간 확보 */
  background-color: #ffffff;  /* 배경색 설정 */
}

.services-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.service-category {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.service-category-title {
  flex-shrink: 0; /* 너비가 줄어들지 않도록 설정 */
  margin-right: 2rem; /* 오른쪽에 2rem 여백 설정 */
}

.services-buttons button {
  margin: 0.5rem;
}

.service-category-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap; /* 버튼들을 여러 줄에 걸쳐 배치할 수 있게 함 */
  justify-content: center; /* 가운데 정렬 */
}

h2 {
  font-size: 3rem;  /* 큰 폰트 크기 */
  margin-bottom: 2rem;  /* 아래쪽 여백 설정 */
}

p {
  font-size: 1rem;
  margin: 0.5rem 0;
}

h6 {
  font-size: 1.5rem;
  margin-top: 1rem;
}

h4 {
  font-size: 2.2rem;
  margin-top: 1rem;
}

.h8 {
  font-size: 1.5rem;
  margin: 1rem !important;
}

.vision-video {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* 부모 요소의 너비를 100%로 설정 */
  height: auto;
  background-size: cover;
  background-position: center;
  overflow: hidden; /* 자식 요소가 부모 요소의 경계를 벗어날 경우 잘리도록 설정 */
}

.service-button {
  width: 400px;  /* 버튼 너비 설정 */
  height: 100px;  /* 버튼 높이 설정 */
  font-size: 2rem !important;  /* 버튼 텍스트 크기 설정 */
  color: #ffffff;  /* 텍스트 색상 설정 */
  background-color: #ffffff;  /* 배경색 설정 */
  text-align: center;  /* 텍스트 가운데 정렬 */
  margin: 1rem !important;  /* 버튼 간격 설정 */
}

.company-message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
}

.company-message-image {
  width: 100%;
  max-width: 1200px; /* 원하는 최대 너비로 설정 */
  height: auto; /* 비율을 유지하면서 높이를 자동으로 조절 */
}

/* 추가된 CSS */
.navbar {
  width: 100%;
  max-width: 1200px; /* 원하는 최대 너비로 설정 */
  margin: 0 auto; /* 가운데 정렬 */
}

.navbar-appbar {
  background-color: #ffffff;
}

.navbar-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #ffffff; 
}

.navbar-left, .navbar-right {
  display: flex;
  align-items: center;
}

.navbar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
}

.navbar-logo img {
  width: 100px;
}

.navbar-logo1 img {
  width: 200px;
}

.navbar button, .navbar a, .navbar .MuiButton-root {
  color: #000000;
  margin: 0 0.5rem;
}

.MuiMenuItem-root {
  color: #000000;
}

/* 추가된 CSS */
.navbar-item {
  position: relative;
  display: inline-block;
}

.navbar-dropdown, .navbar-subdropdown {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  width: 200px;
}

.navbar-item:hover .navbar-dropdown, .navbar-subitem:hover .navbar-subdropdown {
  display: block;
}

.navbar-dropdown a, .navbar-subdropdown a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.navbar-dropdown a:hover, .navbar-subdropdown a:hover {
  background-color: #f1f1f1;
}

.navbar-subitem {
  position: relative;
}

.navbar-subitem > a::after {
  content: '>';
  position: absolute;
  right: 10px;
}

.navbar-subdropdown {
  left: 100%;
  top: 0;
}

/* 추가된 CSS */
.navbar-right {
  flex-shrink: 0;
}

.main-content {
  background: #fbfbfc; /* 배경 색상 설정 */
  padding: 2rem 0; /* 내부 여백 설정 */
  box-shadow: 0 2px 4px rgba(145, 144, 144, 0.1); /* 그림자 설정 */
  position: relative; /* 상대 위치 설정 */
  text-align: center; /* 텍스트 가운데 정렬 */
  color: #000000; /* 텍스트 색상 설정 */
  margin-top: -1px; /* 상단 여백 제거 */
}

.hero-section {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* 텍스트 색상을 흰색으로 설정 */
  text-align: center; /* 텍스트를 가운데 정렬 */
}

/* 반응형 디자인 추가 */
@media (max-width: 768px) {
  .hero-section {
    height: 300px;
    background-size: contain; /* 배경 이미지의 크기를 화면에 맞춤 */
  }

  .hero-text {
    bottom: 10px;
    font-size: 1.2rem;
  }

  h2 {
    font-size: 2rem;
  }

  .service-button {
    width: 90%;  /* 버튼 너비를 90%로 설정 */
    font-size: 1rem;
  }

  .services-grid {
    gap: 1rem;
  }

  .vision-video {
    width: 100%;
  }

  .vision-video > div {
    padding-top: 100%; /* 16:9 Aspect Ratio */
  }
}