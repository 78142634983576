/* 기본 네비게이션 바 설정 */
.navbar {
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
}

/* 네비게이션 바의 배경색 설정 */
.navbar-appbar {
  background-color: #ffffff;
  width: 100vw;
  margin: 0;
  padding: 0;
  color: #ffffff;
}

/* 네비게이션 바의 툴바 설정 */
.navbar-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  padding: 0 1rem;
  margin: 0;
}

/* 네비게이션 바의 좌측과 우측 설정 */
.navbar-left, .navbar-right {
  display: flex;
  align-items: center;
  position: relative;
}

/* 네비게이션 바의 중앙 설정 */
.navbar-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-grow: 1;
}

/* 로고 이미지 크기 설정 */
.navbar-logo img {
  width: 100px;
}

/* 두 번째 로고 이미지 크기 설정 */
.navbar-logo1 img {
  width: 200px;
}

/* 네비게이션 바의 버튼, 링크 스타일 설정 */
.navbar button, .navbar a, .navbar .MuiButton-root {
  color: #000000;
  margin: 0 0.5rem;
}

/* 메뉴 아이템의 루트 스타일 설정 */
.MuiMenuItem-root {
  color: #000000;
}

/* 네비게이션 아이템 설정 */
.navbar-item {
  position: relative;
  display: inline-block;
}

.navbar-button {
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

/* 드롭다운 및 서브드롭다운 설정 */
.navbar-dropdown, .navbar-subdropdown {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  width: 200px;
}

/* 네비게이션 아이템 호버 시 드롭다운 표시 */
.navbar-item:hover .navbar-dropdown, .navbar-subitem:hover .navbar-subdropdown {
  display: block;
}

/* 드롭다운 링크 스타일 설정 */
.navbar-dropdown a, .navbar-subdropdown a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* 드롭다운 링크 호버 시 배경색 변경 */
.navbar-dropdown a:hover, .navbar-subdropdown a:hover {
  background-color: #f1f1f1;
}

/* 서브아이템 설정 */
.navbar-subitem {
  position: relative;
}

/* 서브아이템의 링크 뒤에 화살표 추가 */
.navbar-subitem > a::after {
  content: '>';
  position: absolute;
  right: 10px;
}

/* 서브드롭다운 위치 설정 */
.navbar-subdropdown {
  left: 100%;
  top: 0;
}

/* 네비게이션 바의 우측 설정 */
.navbar-right {
  flex-shrink: 0;
}

/* 모바일 환경을 위한 반응형 디자인 */
@media (max-width: 768px) {
  .navbar-toolbar {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .navbar-left, .navbar-right {
    width: auto;
    justify-content: space-between;
    padding: 0;
  }
  .navbar-center {
    display: none; /* 모바일 환경에서 네비게이션 바의 중앙 항목 숨기기 */
  }
  .navbar-item {
    width: 100%;
  }
  .navbar-dropdown {
    width: 100%;
  }
  .navbar-subdropdown {
    width: 100%;
    position: relative;
  }
  .navbar-subitem > a::after {
    content: '';
  }
  .navbar-logo img, .navbar-logo1 img {
    width: 77px !important; /* 모바일 환경에서 로고 크기 조정 */
  }
}