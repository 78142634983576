body {
  font-family: Arial, sans-serif; /* 전체 페이지의 폰트 설정 */
}

header {
  background-color: #f5f5f5; /* 헤더의 배경색 설정 */
  padding: 20px; /* 헤더 내부 여백 설정 */
  text-align: center; /* 텍스트를 가운데 정렬 */
}

main {
  padding: 20px; /* 메인 콘텐츠 내부 여백 설정 */
}

input, textarea {
  display: block; /* 블록 레벨 요소로 설정 */
  width: 100%; /* 너비를 100%로 설정 */
  margin-bottom: 10px; /* 아래쪽 여백 설정 */
  padding: 10px; /* 내부 여백 설정 */
  border: 1px solid #ccc; /* 테두리 설정 */
  border-radius: 4px; /* 테두리를 둥글게 설정 */
}

button {
  padding: 10px; /* 내부 여백 설정 */
  background-color: #000000; /* 배경색 설정 */
  color: white; /* 텍스트 색상 설정 */
  border: none; /* 테두리 제거 */
  border-radius: 4px; /* 테두리를 둥글게 설정 */
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 설정 */
  margin-top: -10px; /* 위쪽 여백 설정 */
}

button:hover {
  background-color: #555555; /* 버튼에 마우스를 올렸을 때 배경색 설정 */
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.add-news-button {
  margin-left: 10px; /* 왼쪽 여백 설정 */
  font-size: 16px; /* 폰트 크기 설정 */
  background-color: #000000; /* 배경색 설정 */
  color: white; /* 텍스트 색상 설정 */
  border: none; /* 테두리 제거 */
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 설정 */
}

.add-news-button:hover {
  background-color: #555555; /* 버튼에 마우스를 올렸을 때 배경색 설정 */
}

.main-page {
  text-align: center; /* 텍스트를 가운데 정렬 */
}

.hero-section {
  width: 100%; /* 너비를 100%로 설정 */
  height: 300px; /* 높이를 300px로 설정 */
  background-size: cover; /* 배경 이미지를 컨테이너에 맞게 조절 */
  background-position: center; /* 배경 이미지의 위치를 가운데로 설정 */
  display: flex; /* Flexbox를 사용하여 정렬 */
  justify-content: center; /* 가로 방향으로 가운데 정렬 */
  align-items: center; /* 세로 방향으로 가운데 정렬 */
  color: white; /* 텍스트 색상 설정 */
  text-align: center; /* 텍스트를 가운데 정렬 */
}

.hero-section h1 {
  font-size: 2rem; /* 폰트 크기 설정 */
  font-weight: bold; /* 폰트 두께 설정 */
}

.main-content {
  background: #fbfbfc; /* 배경 색상 설정 */
  padding: 1rem; /* 내부 여백 설정 */
  box-shadow: 0 2px 4px rgba(145, 144, 144, 0.1); /* 그림자 설정 */
  position: relative; /* 상대 위치 설정 */
  text-align: center; /* 텍스트 가운데 정렬 */
  color: #000000; /* 텍스트 색상 설정 */
}

.latest-news {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px auto;
  padding: 3rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%; /* 너비를 100%로 설정 */
  max-width: 1200px; /* 최대 너비를 1200px로 설정 */
  height: 400px;
  cursor: pointer;
  
}

.latest-news-content {
  display: flex; /* Flexbox를 사용하여 정렬 */
  align-items: center; /* 세로 방향으로 가운데 정렬 */
}

.latest-news-image {
  width: 450px; /* 너비를 450px로 설정 */
  height: 400px; /* 높이를 400px로 설정 */
  object-fit: cover; /* 이미지를 컨테이너에 맞게 조절 */
  border-radius: 8px; /* 테두리를 둥글게 설정 */
  margin-right: 20px; /* 오른쪽 여백 설정 */
}

.latest-news-text {
  max-width: 600px; /* 최대 너비를 600px로 설정 */
}

.latest-news-info {
  display: flex; /* Flexbox를 사용하여 정렬 */
  justify-content: space-between; /* 자식 요소들을 양쪽 끝으로 정렬 */
  margin-top: 10px; /* 위쪽 여백 설정 */
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3개의 열을 그리드로 설정 */
  gap: 20px; /* 아이템 간격을 20px로 설정 */
  margin: 20px auto; /* 위, 아래 여백 설정 및 가운데 정렬 */
  width: 100%; /* 너비를 100%로 설정 */
  max-width: 1200px; /* 최대 너비를 1200px로 설정 */
  justify-content: center; /* 가로 방향으로 가운데 정렬 */
  align-items: start; /* 세로 방향으로 위쪽 정렬 */
}

.news-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 100%; /* 높이를 100%로 설정 */
  box-sizing: border-box; /* 박스 크기 설정 */
  width: 100%;
  max-width: 400px; /* 최대 너비 설정 */
  margin: 0 auto; /* 가운데 정렬 */
  cursor: pointer;
}

.news-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}

.news-info {
  display: flex; /* Flexbox를 사용하여 정렬 */
  justify-content: space-between; /* 자식 요소들을 양쪽 끝으로 정렬 */
  margin-top: 10px; /* 위쪽 여백 설정 */
}

.pagination {
  display: flex; /* Flexbox를 사용하여 정렬 */
  justify-content: center; /* 가로 방향으로 가운데 정렬 */
  margin-top: 20px; /* 위쪽 여백 설정 */
}

.pagination button {
  margin: 0 5px; /* 좌우 여백 설정 */
  padding: 10px 20px; /* 내부 여백 설정 */
  border: none; /* 테두리 제거 */
  background-color: #ddd; /* 배경색 설정 */
  cursor: pointer; /* 마우스 포인터를 손가락 모양으로 설정 */
}

.pagination button:hover {
  background-color: #bbb; /* 버튼에 마우스를 올렸을 때 배경색 설정 */
}

.search-and-add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.search-input {
  width: 1150px; /* 고정된 너비 설정 */
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
}


.add-news-container {
  display: flex;
  flex-direction: column; /* 세로 방향 정렬 */
  align-items: center; /* 가로 방향으로 가운데 정렬 */
  margin-top: 50px; /* 위쪽 여백 설정 */
}

.textarea-main {
  width: 50%; /* 너비를 50%로 설정 */
  height: 30px; /* 높이를 30px로 설정 */
  justify-content: center; /* 가로 방향으로 가운데 정렬 */
  align-items: center; /* 세로 방향으로 가운데 정렬 */
}

.textarea-content {
  width: 50%; /* 너비를 50%로 설정 */
  height: 500px; /* 높이를 500px로 설정 */
  justify-content: center; /* 가로 방향으로 가운데 정렬 */
  align-items: center; /* 세로 방향으로 가운데 정렬 */
}

.button {
  width: 200%; /* 너비를 200%로 설정 */
  height: 50px; /* 높이를 50px로 설정 */
  justify-content: center; /* 가로 방향으로 가운데 정렬 */
  align-items: center; /* 세로 방향으로 가운데 정렬 */
  background-color: #ddd; /* 배경색 설정 */
  color: white; /* 텍스트 색상 설정 */
}

.news-view-container {
  width: 100%; /* 너비를 100%로 설정 */
  max-width: 1150px; /* 최대 너비를 1150px로 설정 */
  margin: 10px auto; /* 양쪽 여백을 자동으로 설정하여 가운데 정렬 */
  padding: 25px; /* 내부 여백 설정 */
  border: 1px solid #ddd; /* 테두리 설정 */
  border-radius: 8px; /* 테두리를 둥글게 설정 */
  background-color: #f9f9f9; /* 배경색 설정 */
}

.news-title {
  text-align: center; /* 텍스트를 가운데 정렬 */
  margin-bottom: 10px;
}
.news-author,
.news-date,
.news-views,
.news-likes,
.news-comments {
  margin-bottom: 10px;
}

.news-info-box {
  display: flex; /* Flexbox를 사용하여 정렬 */
  justify-content: space-between; /* 자식 요소들을 양쪽 끝으로 정렬 */
  margin-bottom: 20px; /* 아래쪽 여백 설정 */
  padding: 10px; /* 내부 여백 설정 */
  border: 1px solid #ddd; /* 테두리 설정 */
  border-radius: 8px; /* 테두리를 둥글게 설정 */
  background-color: #f9f9f9; /* 배경색 설정 */
  width: 100%; /* 너비를 100%로 설정 */
}

.content {
  width: 100%; /* 너비를 100%로 설정 */
  max-width: 1130px; /* 최대 너비를 1130px로 설정 */
  margin: 20px auto; /* 양쪽 여백을 자동으로 설정하여 가운데 정렬 */
  padding: 20px; /* 내부 여백 설정 */
  border-radius: 8px; /* 테두리를 둥글게 설정 */
  background-color: #fff; /* 배경색 설정 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 설정 */
  text-align: left; /* 왼쪽 정렬 설정 */
}

.comments-section {
  margin-top: 20px; /* 위쪽 여백 설정 */
}

.comments-list {
  margin-bottom: 20px; /* 아래쪽 여백 설정 */
}

.comment-item {
  padding: 10px; /* 내부 여백 설정 */
  border-bottom: 1px solid #ddd; /* 아래쪽 테두리 설정 */
}

.comment-input-container {
  display: flex; /* Flexbox 사용 */
  gap: 10px; /* 요소 사이 간격 설정 */
}

.comment-input-container textarea,
.comment-input-container input {
  flex: 1; /* 남은 공간을 차지하도록 설정 */
}

.textarea-main {
  width: 100%; /* 너비를 100%로 설정 */
  margin-bottom: 10px; /* 아래쪽 여백 설정 */
  padding: 10px; /* 내부 여백 설정 */
  border: 1px solid #ccc; /* 테두리 설정 */
  border-radius: 4px; /* 테두리를 둥글게 설정 */
}

.textarea-content {
  width: 100%; /* 너비를 100%로 설정 */
  height: 300px; /* 높이를 300px로 설정 */
  margin-bottom: 10px; /* 아래쪽 여백 설정 */
  padding: 10px; /* 내부 여백 설정 */
  border: 1px solid #ccc; /* 테두리 설정 */
  border-radius: 4px; /* 테두리를 둥글게 설정 */
}

.edit-title-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-content-textarea {
  width: 100%;
  height: 800px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

.content-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  object-fit: contain; /* 이미지를 원본 비율을 유지하면서 컨테이너에 맞게 조절 */
}

/* 모바일 환경 */
@media (max-width: 768px) {
  .news-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 0 10px;
  }

  .latest-news {
    display: block; /* 블록 요소로 변경 */
    padding: 1rem;
    width: 100%;
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .latest-news-content {
    display: block; /* 블록 요소로 변경 */
  }

  .latest-news-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .latest-news-text {
    width: 100%;
    max-width: 100%;
  }

  .latest-news-info {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
  }

  .news-item {
    width: 100%;
    max-width: none;
    margin-bottom: 10px;
  }

  .search-input {
    width: 100%;
  }

  .pagination {
    flex-wrap: wrap;
    gap: 5px;
  }
}