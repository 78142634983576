.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem; /* 컨테이너 전체에 패딩 추가 */
  margin-top: 0; /* 네비게이션 바와의 공백 제거 */
}

.image {
  width: 100%;
  height: 1500px !important; /* 웹 환경에서의 높이 설정 */
  background-size: contain; /* 원본 비율을 유지하며 컨테이너에 맞추기 */
  background-position: center;
  background-repeat: no-repeat; /* 이미지 반복을 방지 */
  margin-bottom: 10rem !important; /* 이미지와 표 사이의 간격 조정 */
}

.vision-video {
  margin: 0; /* 비디오와 다른 섹션 사이의 여백 제거 */
  padding: 0; /* 비디오 섹션의 패딩 제거 */
}

.vision-section {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem; /* 비디오와 아래 표 사이의 간격 조정 */
}

.research-table {
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1rem; /* 표와 다음 섹션 사이의 간격 조정 */
}

.research-table table {
  width: 100%;
  border-collapse: collapse; /* 테두리 간격 없애기 */
}

.research-table th,
.research-table td {
  border: 1px solid #ddd; /* 테두리 설정 */
  padding: 8px;
  text-align: center; /* 텍스트 가운데 정렬 */
}

.research-table th {
  background-color: #f2f2f2;
}

@media (max-width: 768px) {
  .container {
    padding-top: 1rem; /* 모바일 환경에서 상단 패딩 제거 */
  }

  .image {
    height: 300px !important; /* 모바일 환경에서의 높이 조정 */
    margin-bottom: 0rem !important; /* 이미지와 표 사이의 간격 조정 */
    margin-top: 0; /* 네비게이션 바와의 공백 제거 */
  }

  .vision-video {
    margin-bottom: 0; /* 모바일 환경에서 비디오와 아래 표 사이의 여백 제거 */
  }
  
  .research-table th,
  .research-table td {
    padding: 6px; /* 모바일 환경에서 패딩 조정 */
  }
}
